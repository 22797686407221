import React, { useState, useEffect } from "react"
import queryString from 'query-string'
import { Link } from "gatsby"
import styled from "styled-components"

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Loading from "../components/Loading"
import MajorError from "../components/MajorError"

import { postData } from "../services/server"

const Content = styled.div`
    li.alert {
        color: red;
    }
`

const OrderCompletedPage = (props) => {
    const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
    const orderUid = params.uid;
  
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState("");

    useEffect(
        () => {
            setLoading(true);
                
            postData("/api/orders/data/completed", { uid: orderUid },
              function(result) {
                setOrder(result);
              },
              function(error) {
                  setError(error);
              },
              function() {
                  setLoading(false);
              }
            );
        }, 
        [orderUid]
    );

    return (
      <>
        <SEO page="order" title="Order Received" />
        <Page
            breadcrumb={[
                {url: "/", title: "Home"},
                {url: "/account/orders", title: "Orders"},
                {url: "/account/order?uid=" + orderUid, title: "Order Details"},
                {url: null, title: "Order Received"}
            ]}
        >
            <PageHeader 
                title="Order Received" 
            />

            {loading ? 
                <Loading />
            : 
                <Content>
			        <h2>Thank You for your order from SignMonkey.com!</h2>

                    <div>
                        Your order number is: 
                        <Link to={"/account/order/?uid=" + order.uid}>
                            #{ order.id }
                        </Link>
                    </div>
                    <br />


                    <h3>What's Next?</h3>

                    <ul>
                        <li>
                            Order acknowledgment email including a copy of your invoice
                        </li>
                        { order.status_name == "pending" &&
                            <li className="alert">
                                Phone call about completing your payment
                            </li>
                        }
                        <li>
                            Phone call from our production team
                        </li>
                        <li>
                            Shipping confirmation email including tracking numbers
                        </li>
                    </ul>
                </Content>
            }

            {(error && error.length>0) && 
                <MajorError 
                    error={error} 
                    log={error}
                    onClose={() => setError("")}
                />
            }
        </Page>
    </>
    )
}

export default OrderCompletedPage